import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCurrentUser } from '../../utils/auth';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import { replaceStrings } from '../../utils/utils';
import { StatisticItem } from '../molecules/StatisticItem';

const useStyle = makeStyles({
  root: {
    padding: lightThemeV2.spacing(4),
    paddingTop: lightThemeV2.spacing(0),
    paddingBottom: lightThemeV2.spacing(2),
    backgroundColor: palette.artic,
    '&>*': {
      flex: 1
    }
  }
});

const TYPE_FILTERS_ID = {
  TOTAL_PROJECTS: 0,
  DONE: 1,
  IN_PROGRESS: 2,
  UPCOMING: 3,
  DEADLINE_EXTENSION: 4,
  ENDING_IN_CURRENT_YEAR: 5
};

const FILTER_SETTINGS = [
  {
    id: TYPE_FILTERS_ID.TOTAL_PROJECTS
  },
  {
    id: TYPE_FILTERS_ID.DONE,
    value: 100,
    operation: '=='
  },
  {
    id: TYPE_FILTERS_ID.IN_PROGRESS,
    value: 0,
    query: {
      physical_progress_greater_than: 0,
      physical_progress_less_than: 100
    }
  },
  {
    id: TYPE_FILTERS_ID.UPCOMING,
    value: 0,
    operation: '=='
  },
  {
    id: TYPE_FILTERS_ID.DEADLINE_EXTENSION,
    query: { with_termination_date_extension: true }
  },
  {
    id: TYPE_FILTERS_ID.ENDING_IN_CURRENT_YEAR,
    query: { ending_in_year: new Date().getFullYear() }
  }
];

const StatisticComp = ({ projectAlias, data, onSelect = () => {}, selected, showInspections = false }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useStyle();
  const currentYear = new Date().getFullYear();

  if (!data) return <></>;

  if (showInspections) {
    return (
      <Grid container spacing={2} className={classes.root}>
        <Grid item>
          <StatisticItem
            label={replaceStrings(intlMessages[`executive.statistics.totalProjects`], [projectAlias.toLowerCase()])}
            value={data.totalProjects}
          />
        </Grid>
        <Grid item>
          <StatisticItem label={intlMessages['executive.statistics.inspections']} value={data.totalInspections} />
        </Grid>
        <Grid item>
          <StatisticItem
            label={replaceStrings(intlMessages[`executive.statistics.projectsWithInspections`], [projectAlias])}
            value={data.projectsWithInspections}
          />
        </Grid>
        <Grid item>
          <StatisticItem
            label={replaceStrings(intlMessages['executive.statistics.inspectionsInCurrentYear'], [currentYear])}
            value={data.inspectionsInCurrentYear}
          />
        </Grid>
        <Grid item>
          <StatisticItem
            label={replaceStrings(intlMessages[`executive.statistics.projectsWithInspectionsInCurrentYear`], [projectAlias, currentYear])}
            value={data.projectsWithInspectionsInCurrentYear}
          />
        </Grid>
        <Grid item>
          <StatisticItem
            label={replaceStrings(intlMessages['executive.statistics.inspectorsWithInspectionsInCurrentYear'], [currentYear])}
            value={data.inspectorsWithInspectionsInCurrentYear}
          />
        </Grid>
      </Grid>
    );
  }

  const handlerSelection = id => {
    if (id === selected) {
      onSelect();
      return;
    }
    onSelect(FILTER_SETTINGS.find(x => x.id === id));
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item>
        <StatisticItem
          active={selected === TYPE_FILTERS_ID.TOTAL_PROJECTS}
          onClick={() => handlerSelection(TYPE_FILTERS_ID.TOTAL_PROJECTS)}
          label={replaceStrings(intlMessages[`executive.statistics.totalProjects`], [projectAlias.toLowerCase()])}
          value={data.totalProjects}
        />
      </Grid>
      <Grid item>
        <StatisticItem
          active={selected === TYPE_FILTERS_ID.DONE}
          onClick={() => handlerSelection(TYPE_FILTERS_ID.DONE)}
          label={intlMessages['executive.statistics.done']}
          value={data.projectsDone}
        />
      </Grid>
      <Grid item>
        <StatisticItem
          active={selected === TYPE_FILTERS_ID.IN_PROGRESS}
          onClick={() => handlerSelection(TYPE_FILTERS_ID.IN_PROGRESS)}
          label={intlMessages['executive.statistics.inProgress']}
          value={data.projectsInProgress}
        />
      </Grid>
      <Grid item>
        <StatisticItem
          active={selected === TYPE_FILTERS_ID.UPCOMING}
          onClick={() => handlerSelection(TYPE_FILTERS_ID.UPCOMING)}
          label={intlMessages['executive.statistics.upcoming']}
          value={data.projectsTodo}
        />
      </Grid>
      <Grid item>
        <StatisticItem
          active={selected === TYPE_FILTERS_ID.DEADLINE_EXTENSION}
          onClick={() => handlerSelection(TYPE_FILTERS_ID.DEADLINE_EXTENSION)}
          label={intlMessages['executive.statistics.deadlineExtension']}
          value={data.withTerminationDateExtension}
        />
      </Grid>
      <Grid item>
        <StatisticItem
          active={selected === TYPE_FILTERS_ID.ENDING_IN_CURRENT_YEAR}
          onClick={() => handlerSelection(TYPE_FILTERS_ID.ENDING_IN_CURRENT_YEAR)}
          label={replaceStrings(intlMessages['executive.statistics.toBeCompletedInCurrentYear'], [currentYear])}
          value={data.endingInCurrentYear}
        />
      </Grid>
    </Grid>
  );
};

StatisticComp.propTypes = {
  data: PropTypes.shape({
    totalProjects: PropTypes.number,
    projectsDone: PropTypes.number,
    projectsInProgress: PropTypes.number,
    projectsTodo: PropTypes.number,
    withTerminationDateExtension: PropTypes.number,
    endingInCurrentYear: PropTypes.number,
    totalInspections: PropTypes.number,
    projectsWithInspections: PropTypes.number,
    inspectionsInCurrentYear: PropTypes.number,
    projectsWithInspectionsInCurrentYear: PropTypes.number,
    inspectorsWithInspectionsInCurrentYear: PropTypes.number
  }),
  projectAlias: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showInspections: PropTypes.bool
};

const mapToProps = state => ({
  projectAlias: (getCurrentUser(state).client || {}).project_alias || 'Projects'
});

export const Statistic = connect(mapToProps)(StatisticComp);
