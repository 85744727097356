import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import InitRoutes from './router';
import { getTheme } from './settings/themes';
import Boot from './redux/boot';
import { store, history } from './redux/store';
import { ClearCacheProvider } from 'react-clear-cache';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationProvider } from './components/organims/Notification/NotificationContext';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={getTheme()}>
        <NotificationProvider>
          <ReduxProvider store={store}>
            <ClearCacheProvider auto>
              <QueryClientProvider client={queryClient}>
                <InitRoutes history={history} />
              </QueryClientProvider>
            </ClearCacheProvider>
          </ReduxProvider>
        </NotificationProvider>
      </MuiThemeProvider>
    </>
  );
};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
