import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { palette } from '../../../settings/themes/colors';
import Button from '../../button';

const useStyles = makeStyles({
  toastContainer: {
    backgroundColor: palette.peakcock,
    padding: '8px 16px',
    borderRadius: '4px',
    color: palette.white,
    maxWidth: '360px',

    '&>* .MuiButton-root': {
      color: 'white',
      minWidth: 0,
      minHeight: 0
    },
    '&>* .MuiButton-text': {
      padding: 0
    },
    '&>* .MuiTypography-body1': {},
    '&>* .MuiTypography-subtitle2': {
      fontWeight: 400
    }
  },
  toastTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start'
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px'
  }
});

export const ToastNotification = ({ title, description, icon, toastId }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <div className={classes.toastTitle}>
        {icon}
        <div className={classes.containerText}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="subtitle2">{description}</Typography>
        </div>
        <Button
          onClick={() => {
            console.log(toastId);

            toast.dismiss(toastId);
          }}
          variant="text"
        >
          <CloseIcon fontSize="small" />
        </Button>
      </div>
    </div>
  );
};

ToastNotification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
  toastId: PropTypes.number
};
