import React, { createContext, useContext, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import { ToastNotification } from './Notification';
import { palette } from '../../../settings/themes/colors';

const NotificationContext = createContext({
  consecutive: 0
});

const notificationTypeSettings = {
  processing: {
    icon: <AutorenewOutlinedIcon style={{ color: palette.azure }} fontSize="small" />
  },
  success: {
    icon: <CheckCircleOutlinedIcon style={{ color: 'green' }} fontSize="small" />
  },
  error: {
    icon: <InfoOutlinedIcon style={{ color: 'red' }} fontSize="small" />
  }
};

const notificationTypes = {
  success: 'success',
  processing: 'processing',
  error: 'error'
};

export const useNotificationContext = () => {
  const { consecutive, setConsecutive } = useContext(NotificationContext);

  const showNotification = (title, description, type) => {
    const notificationType = notificationTypeSettings[type];
    const currentConsecutive = consecutive;
    setConsecutive(consecutive + 1);

    if (!notificationType) {
      console.warn(`Notification type "${type}" is not defined`);
    }

    return toast.custom(
      () => (
        <ToastNotification
          title={title}
          description={description}
          icon={notificationType && notificationType.icon ? notificationType.icon : notificationTypeSettings.success.icon}
          toastId={currentConsecutive}
        />
      ),
      { duration: 4000 }
    );
  };

  return {
    showSuccessNotification: (title, description) => showNotification(title, description, notificationTypes.success),
    showProcessingNotification: (title, description) => showNotification(title, description, notificationTypes.processing),
    showErrorNotification: (title, description) => showNotification(title, description, notificationTypes.error)
  };
};

export const NotificationProvider = ({ children }) => {
  const [consecutive, setConsecutive] = useState(0);

  return (
    <NotificationContext.Provider
      value={{
        consecutive,
        setConsecutive
      }}
    >
      {children}
      <Toaster position="bottom-left" />
    </NotificationContext.Provider>
  );
};
