import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import CurrencyInput from '../../../currencyField';
import { ReadOnlyInput } from '../../../../../../components/atoms/ReadOnlyInput';
import { removeDecimalsFromAmount } from '../../utils';
import { ErrorMessage } from '../../../../../../components/organims/ErrorMessage';

export const AmountFieldByBudgetLine = ({ budgetLine, implementingUnitId, totalValue, currency, error, InputProps = {}, ...props }) => {
  const { messages: intlMessages } = useIntl();
  const inputLabel = InputProps.label;
  const escapedValue = removeDecimalsFromAmount(props.value);
  const percentage = totalValue && totalValue > 0 ? ((escapedValue || 0) / totalValue) * 100 : 0;

  const budgetAmountAvailable = useMemo(() => {
    if (!implementingUnitId || implementingUnitId === '') return -1;

    const implementingUnitDetail = budgetLine.taskBudgetLineImplementingUnitDetails.find(x => x.contactId === implementingUnitId);
    return implementingUnitDetail && implementingUnitDetail.amount > 0
      ? implementingUnitDetail.amount - implementingUnitDetail.executedAmount
      : 0;
  }, [implementingUnitId, budgetLine]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  });

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <Typography>{budgetLine.description}</Typography>
        <Typography>{budgetLine.name}</Typography>
        {budgetAmountAvailable > 0 && (
          <Typography>
            {`${intlMessages['project.budget.card.balance']}: ${formatter.format(budgetAmountAvailable)} ${currency}`}
          </Typography>
        )}
        {budgetAmountAvailable === 0 && <ErrorMessage error={error}>{error}</ErrorMessage>}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item container xs={12} spacing={4}>
          <Grid item xs={9}>
            <CurrencyInput {...props} suffix={currency} required={false} label={inputLabel} />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyInput value={`% ${percentage.toFixed(2)}`} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ErrorMessage error={false}>{error}</ErrorMessage>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  );
};

AmountFieldByBudgetLine.propTypes = {
  budgetLine: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  currency: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  implementingUnitId: PropTypes.string
};
